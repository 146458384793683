main {
  padding: 5px 10px;
  background-color: white;
}

main h2 {
  color: #240eb4;
  text-align: center;
  margin-bottom: 0px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 20px;
}

.card {
  position: relative;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  background: white;
  border-radius: 8px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card img {
  width: 100%;
  height: 250px;
  object-fit: fill;

}

.card p {
  margin: 10px 0 0;
  text-align: center;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(38, 186, 172, 0.9);
  color: #191818;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.5s;
}

.card:hover .content {
  opacity: 1;
}

.content h3 {
  margin: 0 0 10px;
  font-size: 1.2em;
  color: #191818;
  text-align: center;
  
}

.content p {
  margin: 0;
  font-size: 0.9em;
  color: #191818;
}

@media (max-width: 768px) {
  .cards {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .cards {
    grid-template-columns: 1fr;
  }
}
