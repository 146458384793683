header {
  padding: 1em 2em;
  margin: 0 auto 1em auto;
  background-color: aquamarine;
  background-image: url(./../images/mainBanner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 4px 8px rgba(0.1, 0.1, 0.1, 0.5);
  color: white;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
}

.logo {
  padding: 0;
  position: absolute;
  right: 15px;
  width: 120px;
  height: 120px;
  top: 0px;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px 280px 0px 50px;
}

.title {
  color: orangered;
  font-size: 50px;
  font-family: Copperplate;
  padding-top: 100px;
  margin-right: 20px;
}

@media (max-width: 1200px) {
  .title {
    font-size: 45px;
  }
  .logo {
    width: 80px;
    height: 80px;
    top: 0px;
  }
}

@media (max-width: 768px) {
  header {
    height: auto;
  }

  .title-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0;
  }

  .title {
    font-size: 35px;
    padding-top: 0;
  }

  .logo {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 30px;
  }

  .logo {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 20px;
  }

  .logo {
    width: 40px;
    height: 40px;
  }
}
