body {
  font-family: Copperplate;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  width: 100%;
}
